import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";

const subTitle = "Popular Exams";
const title = "Popular Exams Preparation We Support";

const courseList = [
  {
    imgUrl: "assets/images/course/ibps.png",
    imgAlt: "rrb",
    title: "Banking Exams",
    btnText: "Explore",
    url: "/banking-exam",
  },
  {
    imgUrl: "assets/images/course/rrb.png",
    imgAlt: "RRB Exams",
    title: "Railway Exams",
    btnText: "Explore",
    url: "railway-exam",
  },
  {
    imgUrl: "assets/images/course/ssc.png",
    imgAlt: "SSC Exams",
    title: "SSC Exams",
    btnText: "Explore",
    url: "ssc-exam",
  },
];

const CourseTwo = () => {
  return (
    <div className="course-section padding-tb">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-1">
            {courseList.map((val, i) => (
              <div className="col" key={i}>
                <div className="course-item style-2">
                  <div className="course-inner">
                    <div className="course-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="course-content">
                      <a href="/course-single">
                        <h4>{val.title}</h4>
                      </a>

                      <div className="course-footer">
                        <div className="course-btn">
                          <Link to={`${val.url}`} className="lab-btn-text">
                            {val.btnText}
                            <i className="icofont-external-link"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseTwo;
