import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import UserDetailsModal from "../Modals/userDetailsModal";

const AccessBar = () => {
  const [openModel, setOpenModal] = useState(false);

  const openusermodel = () => {
    setOpenModal(true);
  };

  return (
    <>
      <UserDetailsModal showModal={openModel} setShowModal={setOpenModal} />
      <div>
        <div
          style={{
            marginTop: "90vh",
            height: "80px",
            background: "linear-gradient(90deg, #9013FE 0%, #104ADE 100%)",

            zIndex: 2,
            width: "100%",
            position: "fixed",
          }}
        >
          <div
            style={{
              marginLeft: "10%",
              paddingTop: "20px",
              display: "flex",
              width: "80%",
              justifyContent: "space-around",
            }}
          >
            <div>
              <span style={{ color: "white" }}>Sign Up Now & </span>{" "}
              <img src={"assets/images/signup.png"} alt="" />
            </div>
            <div>
              <Button
                onClick={openusermodel}
                style={{ background: "#e9db54", border: "none", color: "#000" }}
              >
                Get Free Access Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessBar;
