import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { visitorsLogin } from "../../store/slices/Visitors";
import { FaTimes } from "react-icons/fa";



function UserDetailsModal({ showModal, setShowModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const handleCloseModal = () => {
    setShowModal(false);
    setErrors({});
    setData({});
  };
  const [data, setData] = useState({
    name: "",
    mobileNo: "",
    email: "",
    qualification: "",
  });

  const options = [
    "Select",
    "Doctorate",
    "Masters",
    "Honors Degree",
    "Bachelors",
    "Undergraduate",
    "Associates Degree",
    "Diploma",
    "High School",
    "Less than High School",
    "Others",
    "PUC",
    "BHM",
  ];

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^[a-zA-Z0-9@&'’ -]{3,50}$/.test(name);
  };

  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const isValidEmail = (email) => {
    // Add your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleChange = (e) => {
    const validationErrors = { ...errors };
    let { name, value } = e.target;

    switch (name) {
      case "name":
        validationErrors[name] =
          value === "" || !isValidName(value) ? "Please enter valid name" : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;

      case "email":
        validationErrors[name] =
          value.trim() === "" || !isValidEmail(value)
            ? "Please enter valid email"
            : "";
        if (value.trim() !== "" && isValidEmail(value)) {
          validationErrors[name] = "";
        }
        break;
      case "mobileNo":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        validationErrors[name] =
          value.trim() === "" || !isValidmobile(value)
            ? "Please enter valid mobile number"
            : "";
        if (value.trim() !== "" && isValidmobile(value)) {
          validationErrors[name] = "";
        }
        break;
      case "qualification":
        validationErrors[name] =
          value.trim() === "" ? "Please select qualifiction" : "";
        if (value.trim() !== "") {
          validationErrors[name] = "";
        }
        break;
      default:
        break;
    }
    setData({
      ...data,
      [name]: value,
    });
    setErrors(validationErrors);
  };

  const submit = (e) => {
    e.preventDefault();
    let validationErrors = {};
    if (!data.name) {
      validationErrors.name = "Please enter your  name";
    } else if (!isValidName(data.name)) {
      validationErrors.name = "Please enter valid name";
    }
    if (!data.email) {
      validationErrors.email = "Please enter your email";
    } else if (!isValidEmail(data.email)) {
      validationErrors.email = "Please enter valid email";
    }
    if (!data.mobileNo) {
      validationErrors.mobileNo = "Please enter your mobile number";
    } else if (!isValidmobile(data.mobileNo)) {
      validationErrors.mobileNo = "Please enter valid mobile number";
    }
    if (!data.qualification) {
      validationErrors.qualification = "Please select qualification";
    }
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    } else {
      dispatch(visitorsLogin(data))
        .unwrap()
        .then((data1) => {
          alert("Request submitted successfully");
          handleCloseModal();
        })
        .catch(({ message }) => {
          // setModalMessage(message);
          // setShowModal(true);
        });
    }
  };

  return (
    <>
      <Modal
      style={{
    marginTop:"50px",
        height: "auto",
      }}
      centered
      className="modalMain"
      show={showModal}
      onHide={handleCloseModal}
      >
 <div className="d-flex justify-content-end p-2 5 0 0">
          <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
            <FaTimes />
          </div>
        </div>
      <Modal.Body className="modalbody"  >
      <h3 className="text-center">Join LIFE App</h3>
          <Row className="justify-content-center">
            <Col md={10} lg={10} sm={10}>
            <Form className="p-1">
              <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter name"
                    autoComplete="new-password" // Disable autofill
                    isInvalid={!!errors.name}
                  />

                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={data.email}
                    isInvalid={!!errors.email}
                    name="email"
                    onChange={handleChange}
                    autoComplete="new-password" // Disable autofill
                    className="form-control"
                    placeholder="Enter email"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    value={data.mobileNo}
                    isInvalid={!!errors.mobileNo}
                    name="mobileNo"
                    onChange={handleChange}
                    className="form-control"
                    autoComplete="new-password" // Disable autofill
                    placeholder="Enter mobile number"
                  />
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.mobileNo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Qualification</Form.Label>

                  <Form.Control
                    className="form-control"
                    value={data.qualification}
                    isInvalid={!!errors.qualification}
                    name="qualification"
                    onChange={handleChange}
                    as="select"
                    autoComplete="new-password" // Disable autofill
                  >
                    {options.map((el, index) => (
                      <option key={index}>{el}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid" className="text-start">
                    {errors.qualification}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Col>
          </Row>

          <div className="d-flex justify-content-center">
            <button style={{border:" solid 1px #f16127", height:"40px", width:"100px"}} className="btn cancelBtn m-2" onClick={handleCloseModal}>
              Cancel
            </button>
            <button className="btn saveBtn m-2" style={{background:"#f16127", height:"40px", width:"100px"}} onClick={submit}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserDetailsModal;
