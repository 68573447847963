import React from "react";
import NavHeader from "./SecondNavbar";

import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <NavHeader />
      <div className="container mt-5">
        <div className="d-md-flex">
          <div className="help-left text-left p-3 order-md-0">
            <div className="help-lef-inner mt-3">
              <p>
                <strong>PRIVACY NOTICE</strong>
              </p>
              <p>
                <strong>Last updated&nbsp;August 17, 2023</strong>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                Thank you for choosing to be part of our community at&nbsp;Octal
                Optimum Technologies Private Limited, doing business
                as&nbsp;Octal Optimum&nbsp;("<strong>Octal Optimum</strong>", "
                <strong>we</strong>", "<strong>us</strong>", "
                <strong>our</strong>"). We are committed to protecting your
                personal information and your right to privacy. If you have any
                questions or concerns about this privacy notice, or our
                practices with regards to your personal information, please
                contact us at&nbsp;support@octaloptimum.com.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                When you&nbsp;visit our website&nbsp;
                <a href="http://www.learningmastery.in">
                  http://www.learningmastery.in
                </a>
                &nbsp;(the "<strong>Website</strong>"),&nbsp;use our mobile
                application,&nbsp;as the case may be (the "
                <strong>App; LIFE</strong>")&nbsp;and more generally, use any of
                our services (the "<strong>Services</strong>", which include
                the&nbsp;Website&nbsp;and&nbsp;App), we appreciate that you are
                trusting us with your personal information. We take your privacy
                very seriously. In this privacy notice, we seek to explain to
                you in the clearest way possible what information we collect,
                how we use it and what rights you have in relation to it. We
                hope you take some time to read through it carefully, as it is
                important. If there are any terms in this privacy notice that
                you do not agree with, please discontinue use of our Services
                immediately.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                This privacy notice applies to all information collected through
                our Services (which, as described above, includes
                our&nbsp;Website&nbsp;and&nbsp;App), as well as, any related
                services, sales, marketing or events.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  Please read this privacy notice carefully as it will help you
                  understand what we do with the information that we collect.
                </strong>
              </p>
              <p>
                <strong>TABLE OF CONTENTS</strong>
              </p>
              <ol style={{ textWrap: "wrap" }}>
                <li>
                  <u>WHAT INFORMATION DO WE COLLECT?</u>
                </li>
                <li>
                  <u> HOW DO WE USE YOUR INFORMATION?</u>
                </li>
                <li>
                  <u> WILL YOUR INFORMATION BE SHARED WITH ANYONE?</u>
                </li>
                <li>
                  <u> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</u>
                </li>
                <li>
                  <u> HOW LONG DO WE KEEP YOUR INFORMATION?</u>
                </li>
                <li>
                  <u> HOW DO WE KEEP YOUR INFORMATION SAFE?</u>
                </li>
                <li>
                  <u> DO WE COLLECT INFORMATION FROM MINORS?</u>
                </li>
                <li>
                  <u> WHAT ARE YOUR PRIVACY RIGHTS?</u>
                </li>
                <li>
                  <u> CONTROLS FOR DO-NOT-TRACK FEATURES</u>
                </li>
                <li>
                  <u> DO WE MAKE UPDATES TO THIS NOTICE?</u>
                </li>
                <li>
                  <u> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u>
                </li>
                <li>
                  <u>
                    {" "}
                    HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </u>
                </li>
                <li>
                  <strong>
                    {" "}
                    WHAT INFORMATION DO WE COLLECT?{" "}
                    <u>
                      <br />
                    </u>
                    Personal information you disclose to us
                  </strong>
                </li>
              </ol>
              <p>
                <strong>
                  <em>In Short:&nbsp;</em>
                </strong>
                <em style={{ textWrap: "wrap", textAlign: "justify" }}>
                  We collect personal information that you provide to us.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We collect personal information that you voluntarily provide to
                us when you&nbsp;register on the&nbsp;Services,&nbsp;express an
                interest in obtaining information about us or our products and
                Services, when you participate in activities on
                the&nbsp;Services&nbsp;or otherwise when you contact us.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                The personal information that we collect depends on the context
                of your interactions with us and the&nbsp;Services, the choices
                you make and the products and features you use. The personal
                information we collect may include the following:
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>Personal Information Provided by You.</strong> We
                collect&nbsp;names;&nbsp;phone numbers;&nbsp;email; PAN number;
                Aadhar number; addresses;&nbsp;passwords;&nbsp;and other similar
                information.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>Payment Data.</strong>&nbsp;We may collect data
                necessary to process your payment if you make purchases, such as
                your payment instrument number (such as a credit card number),
                and the security code associated with your payment instrument.
                All payment data is stored by Kotak Bank AllPay Payment gateway.
                You may find their privacy notice link(s)
                here:&nbsp;https://www.allpay.net/legal/terms-of-use
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                All personal information that you provide to us must be true,
                complete and accurate, and you must notify us of any changes to
                such personal information.
              </p>
              <p>
                <strong>Information automatically collected</strong>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  <em>In Short:&nbsp;</em>
                </strong>
                <em>
                  Some information &mdash; such as your Internet Protocol (IP)
                  address and/or browser and device characteristics &mdash; is
                  collected automatically when you use our&nbsp;Services.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We automatically collect certain information when you visit, use
                or navigate the&nbsp;Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use
                our&nbsp;Services&nbsp;and other technical information. This
                information is primarily needed to maintain the security and
                operation of our&nbsp;Services, and for our internal analytics
                and reporting purposes.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </p>
              <p>The information we collect includes:</p>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <em>Log and Usage Data.</em>&nbsp;Log and usage data is
                  service-related, diagnostic, usage and performance information
                  our servers automatically collect when you access or use
                  our&nbsp;Services&nbsp;and which we record in log files.
                  Depending on how you interact with us, this log data may
                  include your IP address, device information, browser type and
                  settings and information about your activity in
                  the&nbsp;Services&nbsp;(such as the date/time stamps
                  associated with your usage, pages and files viewed, searches
                  and other actions you take such as which features you use),
                  device event information (such as system activity, error
                  reports (sometimes called 'crash dumps') and hardware
                  settings).
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <em>Device Data.</em>&nbsp;We collect device data such as
                  information about your computer, phone, tablet or other device
                  you use to access the&nbsp;Services. Depending on the device
                  used, this device data may include information such as your IP
                  address (or proxy server), device and application
                  identification numbers, location, browser type, hardware model
                  Internet service provider and/or mobile carrier, operating
                  system and system configuration information.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <em>Location Data.</em>&nbsp;We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access
                  the&nbsp;Services. For example, we may use GPS and other
                  technologies to collect geolocation data that tells us your
                  current location (based on your IP address). You can opt out
                  of allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. Note however, if you choose to opt
                  out, you may not be able to use certain aspects of the
                  Services.
                </li>
              </ul>
              <p>
                <strong>Information collected through our App</strong>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  <em>In Short:&nbsp;</em>
                </strong>
                <em>
                  We collect information regarding
                  your&nbsp;geolocation,&nbsp;mobile device,&nbsp;push
                  notifications,&nbsp;when you use our App.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                If you use our App, we also collect the following information:
              </p>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <em>Geolocation Information.</em>&nbsp;We may request access
                  or permission to and track location-based information from
                  your mobile device, either continuously or while you are using
                  our App, to provide certain location-based services. If you
                  wish to change our access or permissions, you may do so in
                  your device's settings.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <em>Mobile Device Access.</em>&nbsp;We may request access or
                  permission to certain features from your mobile device,
                  including your mobile
                  device's&nbsp;calendar,&nbsp;camera,&nbsp;reminders,
                  storage,&nbsp;and other features. If you wish to change our
                  access or permissions, you may do so in your device's
                  settings.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <em>Mobile Device Data.&nbsp;</em>We automatically collect
                  device information (such as your mobile device ID, model and
                  manufacturer), operating system, version information and
                  system configuration information, device and application
                  identification numbers, browser type and version, hardware
                  model, Internet service provider and/or mobile carrier, and
                  Internet Protocol (IP) address (or proxy server). If you are
                  using our App, we may also collect information about the phone
                  network associated with your mobile device, your mobile
                  device&rsquo;s operating system or platform, the type of
                  mobile device you use, your mobile device&rsquo;s unique
                  device ID and information about the features of our App you
                  accessed.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <em>Push Notifications.&nbsp;</em>We may request to send you
                  push notifications regarding your account or certain features
                  of the App. If you wish to opt-out from receiving these types
                  of communications, you may turn them off in your device's
                  settings.
                </li>
              </ul>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                This information is primarily needed to maintain the security
                and operation of our App, for troubleshooting and for our
                internal analytics and reporting purposes.
              </p>
              <ol start="2">
                <li>
                  <strong> HOW DO WE USE YOUR INFORMATION?</strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  <em>In Short:&nbsp;</em>
                </strong>
                <em>
                  We process your information for purposes based on legitimate
                  business interests, to fulfil our contract with you,
                  compliance with our legal obligations, and/or your consent.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We use personal information collected via
                our&nbsp;Services&nbsp;for a variety of business purposes
                described below. We process your personal information for these
                purposes in reliance on our legitimate business interests, in
                order to enter into or perform a contract with you, with your
                consent, and/or for compliance with our legal obligations. We
                indicate the specific processing grounds we rely on next to each
                purpose listed below.
              </p>
              <p>We use the information we collect or receive:</p>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>
                    To facilitate account creation and logon process.
                  </strong>
                  &nbsp;If you choose to link your account with us to a
                  third-party account (such as your Google or Facebook account),
                  we use the information you allowed us to collect from those
                  third parties to facilitate account creation; login and logon
                  process for the performance of the contract.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>To post testimonials.</strong>&nbsp;We post
                  testimonials on our&nbsp;Services&nbsp;that may contain
                  personal information. Prior to posting a testimonial, we will
                  obtain your consent to use your name and the content of the
                  testimonial. If you wish to update, or delete your
                  testimonial, please contact us
                  at&nbsp;support@octaloptimum.com&nbsp;and be sure to include
                  your name, testimonial location, and contact information.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Request feedback.&nbsp;</strong>We may use your
                  information to request feedback and to contact you about your
                  use of our&nbsp;Services.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>To enable user-to-user communications.</strong>
                  &nbsp;We may use your information in order to enable
                  user-to-user communications with each user's consent.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>To manage user accounts.&nbsp;</strong>We may use your
                  information for the purposes of managing our account and
                  keeping it in working order.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>
                    To send administrative information to you.&nbsp;
                  </strong>
                  We may use your personal information to send you product,
                  service and new feature information and/or information about
                  changes to our terms, conditions, and policies.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>To protect our Services.&nbsp;</strong>We may use your
                  information as part of our efforts to keep
                  our&nbsp;Services&nbsp;safe and secure (for example, for fraud
                  monitoring and prevention).
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>
                    To enforce our terms, conditions and policies for business
                    purposes, to comply with legal and regulatory requirements
                    or in connection with our contract.
                  </strong>
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>
                    To respond to legal requests and prevent harm.&nbsp;
                  </strong>
                  If we receive a subpoena or other legal request, we may need
                  to inspect the data we hold to determine how to respond.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Fulfil and manage your orders.&nbsp;</strong>We may
                  use your information to fulfil and manage your orders,
                  payments, returns, and exchanges if any made through
                  the&nbsp;Services.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Administer prize draws and competitions.</strong>
                  &nbsp;We may use your information to administer prize draws
                  and competitions when you elect to participate in our
                  competitions.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>
                    To deliver and facilitate delivery of services to the user.
                  </strong>
                  &nbsp;We may use your information to provide you with the
                  requested service.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>
                    To respond to user inquiries/offer support to users.
                  </strong>
                  &nbsp;We may use your information to respond to your inquiries
                  and solve any potential issues you might have with the use of
                  our Services.
                </li>
              </ul>
              <ol start="3">
                <li>
                  <strong style={{ textWrap: "wrap", textAlign: "justify" }}>
                    {" "}
                    WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  <em>In Short:</em>
                </strong>
                <em>
                  &nbsp;We only share information with your consent, to comply
                  with laws, to provide you with services, to protect your
                  rights, or to fulfil business obligations.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We may process or share your data that we hold based on the
                following legal basis:
              </p>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Consent:</strong>&nbsp;We may process your data if you
                  have given us specific consent to use your personal
                  information for a specific purpose.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Legitimate Interests:</strong>&nbsp;We may process
                  your data when it is reasonably necessary to achieve our
                  legitimate business interests.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Performance of a Contract:</strong>&nbsp;Where we have
                  entered into a contract with you, we may process your personal
                  information to fulfil the terms of our contract.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Legal Obligations:</strong>&nbsp;We may disclose your
                  information where we are legally required to do so in order to
                  comply with applicable law, governmental requests, a judicial
                  proceeding, court order, or legal process, such as in response
                  to a court order or a subpoena (including in response to
                  public authorities to meet national security or law
                  enforcement requirements).
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Vital Interests:</strong>&nbsp;We may disclose your
                  information where we believe it is necessary to investigate,
                  prevent, or take action regarding potential violations of our
                  policies, suspected fraud, situations involving potential
                  threats to the safety of any person and illegal activities, or
                  as evidence in litigation in which we are involved.
                </li>
              </ul>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                More specifically, we may need to process your data or share
                your personal information in the following situations:
              </p>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Business Transfers.</strong>&nbsp;We may share or
                  transfer your information in connection with, or during
                  negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Google Maps Platform APIs.</strong>&nbsp;We may share
                  your information with certain Google Maps Platform APIs (e.g.,
                  Google Maps API, Place API).&nbsp;To find out more about
                  Google&rsquo;s Privacy Policy, please refer to this&nbsp;
                  <a href="https://policies.google.com/privacy">link</a>.
                </li>
              </ul>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>Business Partners.&nbsp;</strong>We may share your
                  information with our business partners to offer you certain
                  products, services or promotions.
                </li>
              </ul>
              <ol start="4">
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>
                    {" "}
                    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </strong>
                </li>
              </ol>
              <p>
                <strong>
                  <em>In Short:&nbsp;</em>
                </strong>
                <em style={{ textWrap: "wrap", textAlign: "justify" }}>
                  We may use cookies and other tracking technologies to collect
                  and store your information.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We may use cookies and similar tracking technologies (like web
                beacons and pixels) to access or store information. Specific
                information about how we use such technologies and how you can
                refuse certain cookies is set out in our Cookie Notice.
              </p>
              <ol start="5">
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong> HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  <em>In Short:&nbsp;</em>
                </strong>
                <em>
                  We keep your information for as long as necessary to fulfil
                  the purposes outlined in this privacy notice unless otherwise
                  required by law.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice,
                unless a longer retention period is required or permitted by law
                (such as tax, accounting or other legal requirements). No
                purpose in this notice will require us keeping your personal
                information for longer than&nbsp;the period of time in which
                users have an account with us.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and
                isolate it from any further processing until deletion is
                possible.
              </p>
              <ol start="6">
                <li>
                  <strong style={{ textWrap: "wrap", textAlign: "justify" }}>
                    {" "}
                    HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </strong>
                </li>
              </ol>
              <p>
                <strong>
                  <em>In Short:&nbsp;</em>
                </strong>
                <em style={{ textWrap: "wrap", textAlign: "justify" }}>
                  We aim to protect your personal information through a system
                  of organizational and technical security measures.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We have implemented appropriate technical and organizational
                security measures designed to protect the security of any
                personal information we process. However, despite our safeguards
                and efforts to secure your information, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your information.
                Although we will do our best to protect your personal
                information, transmission of personal information to and from
                our&nbsp;Services&nbsp;is at your own risk. You should only
                access the&nbsp;Services&nbsp;within a secure environment.
              </p>
              <ol start="7">
                <li>
                  <strong style={{ textWrap: "wrap", textAlign: "justify" }}>
                    {" "}
                    DO WE COLLECT INFORMATION FROM MINORS?
                  </strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  <em>In Short:</em>
                </strong>
                <em>
                  &nbsp;We do not knowingly collect data from or market to
                  children under 18 years of age.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We do not knowingly solicit data from or market to children
                under 18 years of age. By using the&nbsp;Services, you represent
                that you are at least 18 or that you are the parent or guardian
                of such a minor and consent to such minor / dependent&rsquo;s
                use of the&nbsp;Services. If we learn that personal information
                from users less than 18 years of age has been collected, we will
                deactivate the account and take reasonable measures to promptly
                delete such data from our records. If you become aware of any
                data we may have collected from children under age 18, please
                contact us at&nbsp;support@octaloptimum.com.
              </p>
              <ol start="8">
                <li>
                  <strong> WHAT ARE YOUR PRIVACY RIGHTS?</strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  <em>In Short:</em>
                </strong>
                <em>
                  &nbsp;You may review, change, or terminate your account at any
                  time.
                </em>
              </p>
              <p>
                <strong>Account Information</strong>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                If you would at any time like to review or change the
                information in your account or terminate your account, you can:
              </p>
              <ul>
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  Log in to your account settings and update your user account.
                </li>
              </ul>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                Upon your request to terminate your account, we will deactivate
                or delete your account and information from our active
                databases. However, we may retain some information in our files
                to prevent fraud, troubleshoot problems, assist with any
                investigations, enforce our Terms of Use and/or comply with
                applicable legal requirements.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>Cookies and similar technologies:</strong>&nbsp;Most Web
                browsers are set to accept cookies by default. If you prefer,
                you can usually choose to set your browser to remove cookies and
                to reject cookies. If you choose to remove cookies or reject
                cookies, this could affect certain features or services of
                our&nbsp;Services. To opt-out of interest-based advertising by
                advertisers on our&nbsp;Services, contact us at
                support@octaloptimum.com.
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>Opting out of email marketing:</strong>&nbsp;You can
                unsubscribe from our marketing email list at any time by
                clicking on the unsubscribe link in the emails that we send or
                by contacting us using the details provided below. You will then
                be removed from the marketing email list &mdash; however, we may
                still communicate with you, for example to send you
                service-related emails that are necessary for the administration
                and use of your account, to respond to service requests, or for
                other non-marketing purposes. To otherwise opt-out, you may,
                contact us at support@octaloptimum.com.
              </p>
              <ol start="9">
                <li>
                  <strong style={{ textWrap: "wrap", textAlign: "justify" }}>
                    {" "}
                    CONTROLS FOR DO-NOT-TRACK FEATURES
                  </strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting
                you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and
                collected. At this stage no uniform technology standard for
                recognizing and implementing DNT signals has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for online tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </p>
              <ol start="10">
                <li>
                  <strong style={{ textWrap: "wrap", textAlign: "justify" }}>
                    {" "}
                    DO WE MAKE UPDATES TO THIS NOTICE?
                  </strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                <strong>
                  <em>In Short:&nbsp;</em>
                </strong>
                <em>
                  Yes, we will update this notice as necessary to stay compliant
                  with relevant laws.
                </em>
              </p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible.
                If we make material changes to this privacy notice, we may
                notify you either by prominently posting a notice of such
                changes or by directly sending you a notification. We encourage
                you to review this privacy notice frequently to be informed of
                how we are protecting your information.
              </p>
              <ol start="11">
                <li>
                  <strong style={{ textWrap: "wrap", textAlign: "justify" }}>
                    {" "}
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                If you have questions or comments about this notice, you
                may&nbsp;email us at&nbsp;support@octaloptimum.com&nbsp;or by
                post to:
              </p>
              <p>Octal Optimum Technologies Private Limited</p>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                #1278, 40<sup>th</sup> Cross, 25<sup>th</sup> Main, 9
                <sup>th</sup> Block Jayanagar, Bengaluru, Karnataka, India
              </p>
              <p>PIN Code: - 560041.</p>
              <ol start="12">
                <li style={{ textWrap: "wrap", textAlign: "justify" }}>
                  <strong>
                    {" "}
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </strong>
                </li>
              </ol>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                Based on the applicable laws of the country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it in some
                circumstances. To request to review, update, modify or delete
                your personal information, contact us at
                Support@octaloptimum.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default PrivacyPolicy;
