
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
// import AboutPage from "./page/about";
// import BlogPage from "./page/blog";
// import BlogPageTwo from "./page/blog-2";
// import BlogPageThree from "./page/blog-3";
// import BlogSingle from "./page/blog-single";
// import CartPage from "./page/cart-page";
// import ContactPage from "./page/contact";
// import CoursePage from "./page/course";
// import CourseSingle from "./page/course-single";
// import CourseView from "./page/course-view";
// import ForgetPass from "./page/forgetpass";
import Home from "./page/home";


import RailwayExam from './page/railway-exam';
import BankingExam from './page/banking-exam';
import SSCExam from './page/ssc-exam';
import AccessBar from "./component/layout/Accessbar";
import Termsandconditions from './component/section/Termsandconditions';
import AppUsagePolicy from './component/section/Appusagepolicy';
import PrivacyPolicy from './component/section/privacy-policy';


function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				

				<Route path="railway-exam" element={<RailwayExam />} />
				<Route path="banking-exam" element={<BankingExam />} />
				<Route path="ssc-exam" element={<SSCExam />} />
				<Route path="terms-and-conditions" element={<Termsandconditions />} />
				<Route path="privacy-policy" element={<PrivacyPolicy />} />
				<Route path="app-usage-policy" element={<AppUsagePolicy />} />

				<Route path="*" element={<ErrorPage />} />
			</Routes>
			
		</BrowserRouter>
	);
}

export default App;
