import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/header-2";

import AccessBar from "../component/layout/Accessbar";

const SSCExam = () => {
  return (
    <>
      <HeaderTwo />

      <Fragment>
        <div>
          <AccessBar />
        </div>

        <div className="pageheader-section style-2">
          <div className="container">
            <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
              <div className="col-lg-7 col-12"></div>
              <div className="col-lg-5 col-12">
                <div className="pageheader-content">
                  <h2 className="phs-title">SSC Exams</h2>
                  {/* <p className="phs-desc">All banking exams</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="course-single-section padding-tb section-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="main-part">
                  <div className="course-item">
                    <div className="course-inner">
                      <div className="course-content">
                        <h3>Course Overview</h3>
                        <p>
                          SSC Exams are a series of competitive examinations
                          conducted by the Staff Selection Commission (SSC) of
                          India. These exams are held to recruit candidates for
                          various Group B and Group C posts in the central
                          government departments, ministries, and subordinate
                          offices.
                        </p>
                        <p>
                          SSC exams usually consist of multiple-choice questions
                          covering subjects such as General Intelligence &
                          Reasoning, General Awareness, Quantitative Aptitude
                          (Mathematics) and English Language & Comprehension.
                        </p>
                        <h4 className="mt-5">Types of SSC Exams</h4>
                        <ul>
                          <li>
                            <b>Combined Graduate Level (CGL) Examination: </b>
                            This is a multi-tiered exam that recruits staff for
                            various Group-B and Group-C posts in various
                            ministries, departments, and organizations of the
                            Government of India.
                          </li>
                          <li>
                            <b>
                              Combined Higher Secondary Level (CHSL)
                              Examination:
                            </b>
                            This exam is conducted to recruit staff for various
                            Group-C and Group-B (Non-Gazetted & Gazetted) posts
                            in various Ministries/Departments/Organizations of
                            the Government of India.
                          </li>
                          <li>
                            <b>SJunior Engineer (JE) Examination: </b> This exam
                            is conducted to recruit Junior Engineers for various
                            Ministries/Departments/Organizations of the
                            Government of India.
                          </li>
                          <li>
                            <b>Stenographer (Grade C & D) Examination: </b>
                            his exam is conducted to recruit Stenographers for
                            various Ministries/Departments/Organizations of the
                            Government of India.
                          </li>
                          <li>
                            <b>Multi-Tasking Staff (MTS) Examination:</b>
                            This exam is conducted to recruit Multi-Tasking
                            Staff for various
                            Ministries/Departments/Organizations of the
                            Government of India.
                          </li>
                        </ul>

                        <h4 className="mt-5">SSC Exam Preparation Tips</h4>
                        <p>
                          Cracking the SSC exam requires a well-rounded
                          strategy. Here are some tips to help you ace it:
                        </p>
                        <p>
                          <b>Lay a Strong Foundation</b>
                          <ul>
                            <li>
                              <b>Grasp the Concepts:</b> Before diving into
                              shortcuts, solidify your understanding of core
                              concepts in each section (Reasoning, Maths,
                              English, GK). This will help you tackle complex
                              problems.
                            </li>
                            <li>
                              <b>Subject-wise Strategies:</b> Research specific
                              strategies for each section. For instance, focus
                              on reading comprehension and vocabulary for
                              English, and practice formulas and time-saving
                              tricks for Quantitative Aptitude.
                            </li>
                          </ul>
                          <b>Plan and Organize</b>
                          <ul>
                            <li>
                              <b>Structured Schedule:</b> Create a study plan
                              that allocates time to each section. Be realistic
                              and consistent with your schedule.
                            </li>
                            <li>
                              <b>Learn from the Experts:</b> Consider enrolling
                              in coaching classes or online resources that
                              provide guidance and structured learning.
                            </li>
                          </ul>
                          <b>Sharpening Your Skills</b>
                          <ul>
                            <li>
                              <b>Mock Tests and Practice Papers:</b> Regularly
                              take mock tests that simulate the actual exam
                              format. Analyze your performance to identify
                              weaknesses and areas for improvement.
                            </li>
                            <li>
                              <b>Revision is Key:</b> Devote time to revising
                              concepts and reviewing mock tests. Use flashcards
                              or short notes for quick revision.
                            </li>
                          </ul>
                          <b>Additional Tips</b>
                          <ul>
                            <li>
                              <b>Stay Updated on Current Affairs:</b> Regularly
                              read newspapers or follow trusted online sources
                              to stay updated on current events for the General
                              Knowledge section.
                            </li>
                            <li>
                              <b>Manage Time Effectively: </b> Practice time
                              management during mock tests to ensure you attempt
                              all sections within the stipulated time frame.
                            </li>
                            <li>
                              <b>Maintain a Positive Attitude: </b> Stay
                              focused, motivated, and believe in yourself
                              throughout your preparation.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    </>
  );
};

export default SSCExam;
