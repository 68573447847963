import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/header-2";
import AboutTwo from "../component/section/about-2";
import BannerTwo from "../component/section/banner-2";
import CategoryTwo from "../component/section/category-2";
import CourseTwo from "../component/section/course-2";
import About from "../component/section/about";

const Home = () => {
  return (
    <Fragment>
      <HeaderTwo />
      <BannerTwo />
      <AboutTwo />
      <CourseTwo />
      <CategoryTwo />

      <About />

      <Footer />
    </Fragment>
  );
};

export default Home;
