import React, { useEffect } from "react";
import NavHeader from "./SecondNavbar";

const AppUsagePolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavHeader />
      <div className="container mt-5">
        <div className="d-md-flex">
          <div className="help-left text-left p-3 order-md-0">
            <h3>App Policy</h3>
            <div className="help-lef-inner mt-3">
              <h4>Creating an Account</h4>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                You can create a LIFE app account by online registration,
                registration process is initiated by reference only. You agree
                not to use any false, inaccurate or misleading information when
                signing up for LIFE account. If you create a LIFE account on
                behalf of an entity, you represent that you have the legal
                authority to bind that entity to these Terms. You cannot
                transfer your LIFE account credentials to another user or
                entity. To protect your account, do not share the OTP generated
                during login, keep it confidential. You are responsible for all
                activity that you do while using the LIFE application for
                learning.
              </p>
              <h4>Account Use</h4>
              <p style={{ textWrap: "wrap", textAlign: "justify" }}>
                You must use your LIFE app on regular/daily basis. This means
                you are not provided with a logout button, to keep your LIFE app
                active and available to use as often as possible. If we
                reasonably suspect that your LIFE app account is at risk of
                being used by anyone other than you fraudulently (for example,
                as a result of an account compromise), Octal Optimum may suspend
                your account until you can reclaim ownership. You are
                discouraged for multiple logins; and your login activities are
                monitored to ensure the best learning experience and optimum
                benefits of learning processes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default AppUsagePolicy;
