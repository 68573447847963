import React, { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import LIFE from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";

const NavHeader = () => {
  const [navBackground, setNavBackground] = useState(false);
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      sticky="top"
      style={{
        transition: "1s ease",
        color: "black",
        backgroundColor: navBackground ? "white" : "white",
      }}
    >
      <Container className="pt-2">
        <Navbar.Brand to="/">
          <Link to="/">
            <img
              src={LIFE}
              width="60"
              height="30"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>&nbsp;</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavHeader;
