import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/header-2";

import AccessBar from "../component/layout/Accessbar";

const RailwayExam = () => {
  return (
    <>
      <HeaderTwo />

      <Fragment>
        <div>
          <AccessBar />
        </div>

        <div className="pageheader-section style-2">
          <div className="container">
            <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
              <div className="col-lg-7 col-12"></div>
              <div className="col-lg-5 col-12">
                <div className="pageheader-content">
                  <h2 className="phs-title">Railway Exams</h2>
                  {/* <p className="phs-desc">All banking exams</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="course-single-section padding-tb section-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="main-part">
                  <div className="course-item">
                    <div className="course-inner">
                      <div className="course-content">
                        <h3>Course Overview</h3>
                        <p>
                          The RRB exam is conducted by the Railway Recruitment
                          Board for various positions in the Indian Railways.
                          There are different RRB exams for different categories
                          of posts, such as the RRB NTPC exam for Non-Technical
                          Popular Categories posts, the RRB Group D exam for
                          Level 1 posts in various departments, and the RRB JE
                          exam for Junior Engineer posts.
                        </p>
                        <p>
                          The RRB exam selection process generally consists of a
                          computer-based test (CBT), followed by a typing skill
                          test or computer-based aptitude test (as applicable),
                          and document verification/medical examination.
                        </p>
                        <h4 className="mt-5">Types of Railway Exams</h4>
                        <ul>
                          <li>
                            <b>Railway Recruitment Board (RRB) Exams: </b>
                            RRB exams are conducted for filling vacancies in
                            technical and non-technical categories, including
                            Assistant Loco Pilot (ALP), Technician, Junior
                            Engineer (JE), Senior Section Engineer (SSE), Clerk,
                            Goods Guard, Station Master, etc.
                          </li>
                          <li>
                            <b>Railway Recruitment Cell (RRC) Exams:</b>
                            RRCs conduct exams for Group D posts and recruitment
                            to specific zones of Indian Railways.
                          </li>
                        </ul>

                        <h4 className="mt-5">Railway Exam Preparation Tips</h4>
                        <p>
                          Cracking a railway exam involves a good study plan and
                          smart preparation. Here are some tips to help you ace
                          your RRB exam:
                        </p>
                        <p>
                          <ul>
                            <li>
                              <b>Know the exam and syllabus:</b> First things
                              first, identify the specific RRB exam you're
                              targeting (NTPC, Group D, JE, etc.) Each has a
                              different syllabus and focus. Download the
                              official notification and syllabus from the RRB
                              website.
                            </li>
                            <li>
                              <b>Craft a study plan:</b> Dedicate dedicated
                              study hours daily/weekly and stick to the
                              schedule. Break down the syllabus into manageable
                              portions and allocate time for each topic.
                            </li>
                            <li>
                              <b>Gather the right resources:</b> NCERT books for
                              Maths, Science and Social Science are great for
                              foundational concepts. Buy reliable study guides
                              or enroll in coaching if needed. Utilize online
                              resources and previous year question papers.
                            </li>
                            <li>
                              <b>Master the art of time management:</b> Railway
                              exams are time-bound. Practice mock tests and
                              previous year papers to improve your speed and
                              accuracy. Learn time management techniques to
                              allocate the right amount of time to each section.
                            </li>
                            <li>
                              <b>Focus on General Awareness (GA):</b> GA is a
                              common section across most RRB exams. Stay updated
                              on current affairs, national and international
                              news, science, history, and basic geography. Read
                              newspapers and magazines regularly.
                            </li>
                            <li>
                              <b>Practice, analyze, repeat:</b> Solve mock tests
                              regularly to identify your weak areas. Analyze
                              your mistakes, revise those topics thoroughly, and
                              practice more questions to solidify understanding.
                            </li>
                            <li>
                              <b>Don't ignore revision:</b> Regular revision is
                              crucial for retaining information. Make short
                              notes and revisit them periodically. Flashcards
                              can be helpful for memorizing key facts and
                              formulas.
                            </li>
                            <li>
                              <b>Stay positive and motivated:</b> Competitive
                              exams can be stressful. Maintain a positive
                              attitude, believe in yourself, and take breaks to
                              avoid burnout. Talk to friends, family or a mentor
                              for support.
                            </li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    </>
  );
};

export default RailwayExam;
